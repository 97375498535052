import { defaultValuesReset } from "../../constants/constants";
import { RESET_PASSWORD } from "../../constants/actions";

 let defaultState = defaultValuesReset;

const reset = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_PASSWORD.RESET_PASSWORD_RECEIVE_RESPONSE_EMAIL_BY_CODE:

      return {
        ...state,
        responseInfoByCode: action.response
      }
     case RESET_PASSWORD.RESET_PASSWORD_GET_EMAIL_BY_CODE:
       return {
         ...state,
         isLoaded: true
       }
      case RESET_PASSWORD.RESET_PASSWORD_USER: {
        return {
          ...state,
          isLoading: true
        }
      }
      case RESET_PASSWORD.RESET_PASSWORD_RECEIVE_RESPONSE: {
        return {
          ...state,
          responseUserResetPassword: action.response,
          isLoading: false
        }
      }
      case RESET_PASSWORD.RESET_PASSWORD_RESET_VALUES:
        return {
          ...state,
          responseUserResetPassword: {}
        }
    default:
      return state
  }
}

export default reset;