import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';

import reset from './redux/reducers/reset';

const sagaMiddleware = createSagaMiddleware();
export default createStore(combineReducers({
    reset,
}), applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
