import { put, takeLatest, call, all } from 'redux-saga/effects';
import { getEmailByCodeReset, sendInfoResetPassword  } from './services/services';
import { RESET_PASSWORD } from './constants/actions';
import { receiveResponseInfoByCode, receiveResponseResetPasswordUser } from './redux/actions/reset';

// AUTH


 function* getEmailByCode(action) {
 	try {
 		const response = yield call(getEmailByCodeReset, action.code);
 		console.log(response)
 		yield put(receiveResponseInfoByCode(response))
 	}
 	catch (e) {
 		console.log(e)
 	}
 }

function* getInfoByCodeWatcher(action) {
	yield takeLatest(RESET_PASSWORD.RESET_PASSWORD_GET_EMAIL_BY_CODE, getEmailByCode)
}
// reseteo de password
 function* putRegisterUser(action) {
 	try {
 		console.log(action)
 		const response = yield call(sendInfoResetPassword, action.user, action.code);
 		console.log(response)
 		yield put(receiveResponseResetPasswordUser(response))
 	}
 	catch (e) {
 		console.log(e)
 	}
 }

function* putResetPasswordWatcher(action) {
	yield takeLatest(RESET_PASSWORD.RESET_PASSWORD_USER, putRegisterUser)
}


export default function* mySaga() {
	yield all([
		getInfoByCodeWatcher(),
		putResetPasswordWatcher(),
		// getInfoByOtpWatcher()
	]) 
}
