 
 import { 
    RESET_PASSWORD
  } from "../../constants/actions";
 
  export const getInfoByCode = (code) => {
      console.log(code)
    return {
      type: RESET_PASSWORD.RESET_PASSWORD_GET_EMAIL_BY_CODE,
      code
    }
  }

 export const receiveResponseInfoByCode = (response) => {
   return {
     type: RESET_PASSWORD.RESET_PASSWORD_RECEIVE_RESPONSE_EMAIL_BY_CODE,
     response
   }
 }

 export const resetPasswordUser = (user, code, email) => {
   return {
     type: RESET_PASSWORD.RESET_PASSWORD_USER,
     user,
     code,
     email
   }
 }

 export const receiveResponseResetPasswordUser = (response) => {
   return {
     type: RESET_PASSWORD.RESET_PASSWORD_RECEIVE_RESPONSE,
     response
   }
 }

 export const resetValues = () => {
   return {
     type: RESET_PASSWORD.RESET_PASSWORD_RESET_VALUES
   }
 }